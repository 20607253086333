import { graphql } from 'gatsby';
import type { FC } from 'react';
import styled from 'styled-components';
import { PageFrame } from '../../components/PageFrame';
import { Colors } from '../../utils/Colors';

type ProjectProps = {
  data: GatsbyTypes.ProjectBySlugQuery;
};

const ProjectInfo = styled.div`
  border: 1px solid ${Colors.Border};
  padding: 1rem;
  margin-bottom: 1.45rem;
  margin-top: 1.45rem;
`;

export const Project: FC<ProjectProps> = ({
  data: { contentfulProject, site },
}) => {
  const technologies = contentfulProject?.technologies
    ?.map((tech) => tech?.name)
    .join(', ');

  return (
    <PageFrame
      description={site?.siteMetadata?.description}
      title={`${site?.siteMetadata?.title ?? ''}.projects/${
        contentfulProject?.title ?? ''
      }`}
    >
      <h1>{contentfulProject?.title}</h1>
      <ProjectInfo>
        <div>
          <strong>Launched:</strong> {contentfulProject?.launchDate}
        </div>
        <div>
          <strong>Built with:</strong> {technologies}
        </div>
      </ProjectInfo>
      <div
        dangerouslySetInnerHTML={{
          __html:
            contentfulProject?.description?.childMarkdownRemark?.html ?? '',
        }}
      />
    </PageFrame>
  );
};
Project.displayName = 'Project';
export default Project;

export const pageQuery = graphql`
  query ProjectBySlug($slug: String!) {
    site {
      siteMetadata {
        description
        title
      }
    }
    contentfulProject(slug: { eq: $slug }) {
      title
      launchDate(formatString: "MMMM DD, YYYY")
      technologies {
        name
      }
      description {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
